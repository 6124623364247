'use client';

import React from 'react';

import styles from './styles.module.css';

import {
  PointerComponentProduct,
  PointerComponentProductItem
} from '@theme/types';
import clsx from 'clsx';
import { Link, Price } from '@theme/components';
import { Image } from '@akinon/next/components/image';
import Masonry from 'react-masonry-css';
import { Tooltip } from 'react-tooltip';
import useWindowResize from '@theme/hooks/use-window-resize';
import { twMerge } from 'tailwind-merge';
interface ProductPointerWidgetProps {
  productItem?: Array<PointerComponentProduct>;
  productPointerItem?: Array<PointerComponentProductItem>;
}

const ProductPointerWidget = (props: ProductPointerWidgetProps) => {
  const { productItem, productPointerItem } = props;
  const { width } = useWindowResize();
  // To avoid opening tooltips of other components on the same page
  const tooltipIdPrefix = 'productPointerBannerItem';

  const breakpointColumnsObj = {
    default: 4,
    768: 2,
    500: 1
  };

  const renderPoint = (filteredItem) => {
    if (width >= 1024) {
      return (
        <Link
          href={filteredItem.value.product_link}
          className={twMerge('absolute', styles.point)}
          style={
            {
              '--point-top-desktop':
                filteredItem?.value?.product_button_desktop_position_top + '%',
              '--point-left-desktop':
                filteredItem?.value?.product_button_desktop_position_left + '%',
              '--point-top-mobile':
                filteredItem?.value?.product_button_mobile_position_top + '%',
              '--point-left-mobile':
                filteredItem?.value?.product_button_mobile_position_left + '%'
            } as React.CSSProperties
          }
          data-tooltip-id={filteredItem?.value?.point_id + tooltipIdPrefix}
        >
          <div className="z-[5] flex items-center justify-center w-4 h-4 bg-black bg-opacity-25 border border-white rounded-full cursor-pointer hover:bg-opacity-0 group">
            <div className="absolute w-2.5 h-2.5 bg-white border border-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
          </div>
        </Link>
      );
    } else {
      return (
        <div
          className={twMerge('absolute', styles.point)}
          style={
            {
              '--point-top-desktop':
                filteredItem?.value?.product_button_desktop_position_top + '%',
              '--point-left-desktop':
                filteredItem?.value?.product_button_desktop_position_left + '%',
              '--point-top-mobile':
                filteredItem?.value?.product_button_mobile_position_top + '%',
              '--point-left-mobile':
                filteredItem?.value?.product_button_mobile_position_left + '%'
            } as React.CSSProperties
          }
          data-tooltip-id={filteredItem?.value?.point_id + tooltipIdPrefix}
        >
          <div className="z-[5] flex items-center justify-center w-4 h-4 bg-black bg-opacity-25 border border-white rounded-full cursor-pointer hover:bg-opacity-0 group">
            <div className="absolute w-2.5 h-2.5 bg-white border border-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
          </div>
        </div>
      );
    }
  };

  const renderTooltip = (filteredItem) => {
    return (
      <Tooltip
        className="flex rounded-lg relative z-40 w-[15.625rem] min-h-[6.25rem] !pointer-events-auto"
        id={filteredItem?.value?.point_id + tooltipIdPrefix}
        openOnClick={width < 1024}
      >
        <Link
          className="flex items-center w-full h-full gap-x-3"
          href={filteredItem.value.product_link}
        >
          <div className="flex justify-center items-center min-w-[5.125rem] h-[5.125rem]">
            <Image
              src={filteredItem?.kwargs?.value?.image?.url}
              alt={filteredItem?.value?.alt}
              width={82}
              height={82}
              loading="lazy"
            />
          </div>
          <div className="flex flex-col h-full gap-y-3">
            {filteredItem?.value?.title && (
              <div className="font-sans text-xs font-medium text-white line-clamp-2">
                {filteredItem?.value?.title}
              </div>
            )}
            {filteredItem?.value?.price && (
              <Price
                customStyle="text-sm text-white"
                value={filteredItem?.value?.price}
              />
            )}
          </div>
        </Link>
      </Tooltip>
    );
  };

  return (
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className="flex my-masonry-grid gap-x-3 lg:gap-x-9"
      columnClassName="my-masonry-grid_column flex flex-col lg:gap-y-9 gap-y-3"
    >
      {productItem.map((item, loopIndex) => (
        <div className="relative" key={item?.value?.alt + loopIndex}>
          {item?.value?.link ? (
            <Link href={item?.value?.link}>
              <Image
                src={item?.kwargs?.value?.desktop_image?.url}
                alt={item?.value?.alt}
                width={400}
                height={400}
              />
            </Link>
          ) : (
            <Image
              src={item?.kwargs?.value?.desktop_image?.url}
              alt={item?.value?.alt}
              width={400}
              height={400}
            />
          )}

          {productPointerItem
            .filter(
              (pointerItem) =>
                pointerItem.value.item_id === item.value.item_id &&
                pointerItem?.kwargs?.value?.is_show_point?.value === 'yes'
            )
            .map((filteredItem, index) => (
              <React.Fragment key={index}>
                {/* POINT ITEM */}
                {renderTooltip(filteredItem)}

                {/* POINT */}
                {renderPoint(filteredItem)}
              </React.Fragment>
            ))}
        </div>
      ))}
    </Masonry>
  );
};

export default ProductPointerWidget;
