'use client';

import React from 'react';
// eslint-disable-next-line @akinon/projectzero/image-import
import { unstable_getImgProps as getImageProps } from 'next/image';
import { Link } from '@theme/components';
import styles from './styles.module.css';
import { ImageType, WidgetResultType } from '@akinon/next/types';

// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import ArrowRightIcon from '@theme/components/icons/arrowRight';
import ArrowLeftIcon from '@theme/components/icons/arrowLeft';
import { calculateIsExpired } from '@theme/utils/calculateIsExpired';
import { IHeroSlider } from '@theme/types';
// register Swiper custom elements
register();

export default function HomeHeroSliderContent({
  data,
  swiperId = 'heroSlider'
}: {
  data: WidgetResultType<IHeroSlider>;
  swiperId?: string;
}) {
  const [isHovered, setIsHovered] = React.useState(false);

  const handleParentHover = () => {
    setIsHovered(true);
  };

  const handleParentLeave = () => {
    setIsHovered(false);
  };

  const heroSliderData = data?.attributes?.hero_slider;
  const autoPlay = data?.attributes?.auto_play?.value;

  const sliderEndDate = heroSliderData
    .filter((slider) => new Date(slider?.value?.end_date) > new Date())
    .map((slider) => new Date(slider?.value?.end_date).toISOString());

  const common = {
    className: 'w-full object-cover h-full'
  };

  const renderSlideImage = (slide, isFirstSlide) => {
    const {
      props: { srcSet: desktop }
    } = getImageProps({
      width: 3456,
      height: 1440,
      quality: 100,
      src: slide?.kwargs?.value?.image?.url,
      alt: slide?.value?.alt,
      ...(isFirstSlide ? { loading: 'eager' } : { loading: 'lazy' }),
      ...common
    });
    const {
      props: { srcSet: mobile, ...rest }
    } = getImageProps({
      width: 750,
      height: 984,
      quality: 100,
      src: slide?.kwargs?.value?.mobile_image?.url,
      alt: slide?.value?.alt,
      ...(isFirstSlide ? { loading: 'eager' } : { loading: 'lazy' }),
      ...common
    });

    return (
      <Link draggable="false" href={slide?.value?.url} className="block h-full">
        <picture className="block h-full">
          <source media="(min-width: 1024px)" srcSet={desktop} />
          <source srcSet={mobile} />
          <img {...rest} />
        </picture>
      </Link>
    );
  };

  const renderSlideVideo = (slide) => {
    return (
      <>
        <video
          className="w-full h-full object-cover lg:hidden"
          muted
          loop
          playsInline
          autoPlay
        >
          <source
            src={slide?.kwargs?.value?.mobile_video?.url}
            type="video/mp4"
          />
        </video>

        <video
          className="w-full h-full object-cover hidden lg:inline-block"
          muted
          loop
          playsInline
          autoPlay
        >
          <source src={slide?.kwargs?.value?.video?.url} type="video/mp4" />
        </video>
      </>
    );
  };

  const renderSlides = () => {
    return heroSliderData?.map((slide, index) => {
      const isExpired = calculateIsExpired(slide?.value?.end_date);
      const isFirstSlide = index === 0;

      // if end_date is not provided, slide is not expired by default
      if (isExpired && !!slide?.value?.end_date) {
        return null;
      }

      const slideWidth =
        slide?.value?.media_type === 'image'
          ? slide?.value?.image_size + '%'
          : '100%';

      return (
        <swiper-slide
          key={JSON.stringify(slide)}
          style={{ '--slide-width': slideWidth } as React.CSSProperties}
          class={twMerge(
            styles.slide,
            'group relative lg:h-auto lg:max-h-[45rem]'
          )}
        >
          {slide?.value?.media_type === 'image'
            ? renderSlideImage(slide, isFirstSlide)
            : slide?.value?.media_type === 'video'
            ? renderSlideVideo(slide)
            : null}

          <div className="absolute z-50 bottom-6 left-6 flex flex-col gap-y-5 2xl:bottom-6 2xl:transition-transform 2xl:duration-500 2xl:tr 2xl:gap-y-0 2xl:translate-y-[4.5625rem] 2xl:group-hover:translate-y-0">
            {slide?.value?.is_show_image_text === 'yes' &&
              slide?.value?.image_text && (
                <div className="flex flex-col gap-y-2">
                  <h2
                    className={
                      'text-xl font-bold leading-[1.5625rem] lg:text-2xl lg:leading-8 2xl:text-[2rem] 2xl:leading-10 font-kronaOne'
                    }
                    style={{ color: slide?.value?.image_text_color }}
                  >
                    {slide?.value?.image_heading}
                  </h2>
                  <p
                    className="text-sm leading-4 lg:text-base lg:leading-5"
                    style={{ color: slide?.value?.image_text_color }}
                  >
                    {slide?.value?.image_text}
                  </p>
                </div>
              )}
            {slide?.value?.is_show_button === 'yes' &&
              slide?.value?.button_text && (
                <Link
                  onMouseEnter={handleParentHover}
                  onMouseLeave={handleParentLeave}
                  href={slide?.value?.url}
                  style={{
                    color: slide?.value?.button_text_color,
                    backgroundColor: slide?.value?.button_background_color
                  }}
                  className="group transition-all flex items-center gap-x-1 px-4 py-[.625rem] rounded-[2rem] w-max 2xl:mt-8 lg:transition-opacity lg:duration-500 hover:lg:brightness-95"
                >
                  <span className="text-sm uppercase">
                    {slide?.value?.button_text}
                  </span>
                  <ArrowRightIcon
                    className={clsx(
                      isHovered ? 'translate-x-[2px]' : '',
                      'transition-all w-5 h-5'
                    )}
                  />
                </Link>
              )}
          </div>
        </swiper-slide>
      );
    });
  };

  return (
    <div className="relative">
      <swiper-container
        slides-per-view="auto"
        id={swiperId}
        class="h-auto"
        navigation="true"
        navigation-next-el={'#custom-nav-button--next-' + swiperId}
        navigation-prev-el={'#custom-nav-button--prev-' + swiperId}
        autoplay={autoPlay}
        loop="true"
      >
        {heroSliderData && renderSlides()}
      </swiper-container>
      {sliderEndDate?.length > 1 && (
        <>
          <button
            id={'custom-nav-button--prev-' + swiperId}
            className="absolute z-[8] top-1/2 4xl:left-12 left-8 max-lg:hidden disabled:opacity-30 w-[3.25rem] h-[3.25rem]"
            aria-hidden="false"
          >
            <ArrowLeftIcon className={'w-[1.25rem] h-[1.25rem] ml-[0.8rem]'} />
          </button>
          <button
            id={'custom-nav-button--next-' + swiperId}
            aria-hidden="false"
            className="absolute z-[8] top-1/2 4xl:right-12 lg:right-8 max-lg:hidden disabled:opacity-30 w-[3.25rem] h-[3.25rem]"
            aria-label="Next Slide"
          >
            <ArrowRightIcon className={'w-[1.25rem] h-[1.25rem] ml-[0.8rem]'} />
          </button>
        </>
      )}
    </div>
  );
}
