'use client';

import React from 'react';
import { Icon, Link } from '@theme/components';
import ArrowRightIcon from '@theme/components/icons/arrowRight';
import useWindowResize from '@theme/hooks/use-window-resize';
import { Image } from '@akinon/next/components/image';
export default function ShopTheLook({ data }) {
  const shopTheLookData = data?.attributes;
  const animationText = shopTheLookData?.animation_text;
  const itemVisibility = shopTheLookData?.item_visibility;
  const theme = shopTheLookData?.background;
  const windowSize = useWindowResize();
  const icons = ['stl-1', 'stl-2', 'stl-3', 'stl-4', 'stl-5'];
  const renderShopTheLookContent = () => {
    return (
      <div className="relative w-full">
        {' '}
        {itemVisibility?.value?.will_animated_text_appear === 'yes' && (
          <div className="flex absolute -left-1/2 z-50 top-1/2 -translate-y-[390%] lg:-translate-y-[280%] xl:-translate-y-[360%] 3xl:-translate-y-[450%] right-0 overflow-hidden">
            <div className="text-nowrap lg:animate-sliding-text-lg animate-sliding-text-mobile inline-flex flex-row whitespace-nowrap">
              <span
                className="flex flex-row text-nowrap lg:text-7xl text-4xl font-kalnia"
                style={{
                  color: animationText?.value?.text_color
                }}
              >
                {Array.from({ length: 70 }, (_, i) => {
                  const icon = icons[i % icons.length];
                  return (
                    <React.Fragment key={i}>
                      <Icon
                        name={icon}
                        size={30}
                        className=" items-center w-fit inline px-8"
                      />
                      {animationText?.value?.text}
                    </React.Fragment>
                  );
                })}
              </span>
            </div>
          </div>
        )}
        <div className="relative -left-1/2 lg:max-w-[43.93rem] max-w-[18.75rem] mx-auto lg:w-[44rem] w-[300px]">
          <div>
            {itemVisibility?.value?.will_title_appear === 'yes' && (
              <p
                className="font-kronaOne pt-12 md:pt-32 lg:pt-[8rem] text-center lg:text-[2rem] text-xl lg:leading-normal"
                style={{ color: theme.value?.text_color }}
              >
                {theme?.value?.title}
              </p>
            )}
            {itemVisibility?.value?.will_description_appear === 'yes' && (
              <p className="font-sans pt-6 pb-8 text-center lg:text-xl text-sm text-white px-[0.875]">
                {theme.value?.description}
              </p>
            )}
          </div>
          {itemVisibility?.value?.will_button_appear === 'yes' && (
            <div className="flex justify-center ">
              <Link
                href={theme.value.url}
                className="font-sans group flex items-center gap-1 explore-the-look-button px-6 lg:py-[.875rem] py-[0.625rem] w-max rounded-[2rem] transition-all bg-white duration-500 hover:brightness-95"
                style={{
                  color: theme.value?.button_text_color
                }}
              >
                <p>{theme.value?.button_text}</p>
                <ArrowRightIcon
                  className="w-6 h-6 transition-all group-hover:translate-x-[4px]"
                  style={{
                    fill: theme.value?.button_text_color
                  }}
                />
              </Link>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderBackground = (type: 'image' | 'video') => {
    const url =
      type === 'image'
        ? theme.kwargs?.value?.bg_image?.url
        : theme.kwargs?.value?.bg_video?.url;
    const isMobile = windowSize?.width < 1024;
    const bgUrl =
      isMobile && type === 'image'
        ? theme.kwargs?.value?.bg_mobil_image?.url
        : url;
    return (
      <div className="shop-the-look mb-8 lg:mb-[4.5rem] relative mx-auto">
        <div className="w-full object-cover">
          <Link className="w-full" href={theme.value.url || '/'}>
            <Image
              className="w-full"
              imageClassName="w-full object-cover"
              src={bgUrl}
              width={1728}
              height={1052}
              alt={'Shop The Look'}
              loading="lazy"
              style={{
                backgroundImage: `url(${bgUrl})`,
                aspectRatio:
                  windowSize?.width >= 1024 ? '1728 / 1052' : '375 / 795'
              }}
            />
          </Link>
          <Link
            className="absolute top-1/2 -translate-y-[50%] lg:-translate-y-[55%] xl:-translate-y-[70%] translate-x-[50%] left-0 right-0"
            href={theme.value.url || '/'}
          >
            {renderShopTheLookContent()}
          </Link>
        </div>
      </div>
    );
  };
  return (
    <div className="relative">
      {renderBackground(theme.kwargs.value.background_type.value)}
    </div>
  );
}
