import(/* webpackMode: "eager" */ "/app/node_modules/@akinon/next/components/image.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/@akinon/next/components/lazy-component.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/@akinon/next/utils/get-currency.ts");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js");
import(/* webpackMode: "eager" */ "/app/src/components/index.ts");
import(/* webpackMode: "eager" */ "/app/src/components/recommendationButton.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/widgets/campaigns-content/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/widgets/card-grid-content/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/widgets/heritage-slider-content/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/widgets/home-hero-slider-content/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/widgets/home-hero-video-content/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/widgets/image-and-description-content/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/widgets/multi-card-row-content/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/widgets/product-pointer-banner-item/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/widgets/recommendation-content/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/widgets/shop-the-look-content/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/widgets/solid-banner-content/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/widgets/pointer-mono-images/points.tsx")