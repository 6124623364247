'use client';

import React, { useEffect } from 'react';
import { Link } from '@theme/components';
import { WidgetResultType } from '@akinon/next/types';
import { calculateIsExpired } from '@theme/utils/calculateIsExpired';
import { IHomeHeroVideo } from '@theme/types';
// eslint-disable-next-line @akinon/projectzero/image-import
import { unstable_getImgProps as getImageProps } from 'next/image';
import ArrowRightIcon from '@theme/components/icons/arrowRight';
import ArrowLeftIcon from '@theme/components/icons/arrowLeft';
import Script from 'next/script';
export default function HomeHeroVideoContent({
  data,
  swiperId
}: {
  data: WidgetResultType<IHomeHeroVideo>;
  swiperId: string;
}) {
  const heroSliderData = data?.attributes?.hero_slider;
  const autoPlay = data?.attributes?.auto_play?.value;

  const sliderEndDate = heroSliderData
    .filter((slider) => new Date(slider?.value?.end_date) > new Date())
    .map((slider) => new Date(slider?.value?.end_date).toISOString());

  const common = {
    className: 'w-full object-cover h-full'
  };

  const renderSlideImage = (slide, isFirstSlide) => {
    const {
      props: { srcSet: desktop }
    } = getImageProps({
      width: 3456,
      height: 1440,
      quality: 100,
      src: slide?.kwargs?.value?.image_video_gif?.url,
      alt: slide?.value?.alt,
      ...common,
      ...(isFirstSlide ? { loading: 'eager' } : { loading: 'lazy' })
    });
    const {
      props: { srcSet: mobile, ...rest }
    } = getImageProps({
      width: 750,
      height: 984,
      quality: 100,
      src: slide?.kwargs?.value?.mobile_image_video_gif?.url,
      alt: slide?.value?.alt,
      ...common,
      ...(isFirstSlide ? { loading: 'eager' } : { loading: 'lazy' })
    });

    return (
      <Link draggable="false" href={slide?.value?.url} className="block h-full">
        <picture className="block h-full">
          <source media="(min-width: 1024px)" srcSet={desktop} />
          <source srcSet={mobile} />
          <img {...rest} />
        </picture>
      </Link>
    );
  };

  const renderSlideVideo = (slide) => {
    return (
      <Link draggable="false" href={slide?.value?.url} className="block h-full">
        <video
          className="w-full h-full object-cover hidden lg:inline-block"
          muted
          loop
          playsInline
          autoPlay
        >
          <source
            src={slide?.kwargs?.value?.image_video_gif?.url}
            type="video/mp4"
          />
        </video>
        <video
          className="w-full h-full object-cover lg:hidden"
          muted
          loop
          playsInline
          autoPlay
        >
          <source
            src={slide?.kwargs?.value?.mobile_image_video_gif?.url}
            type="video/mp4"
          />
        </video>
      </Link>
    );
  };

  const renderSlides = () => {
    let interval = 3000;

    const convertedInterval = Number(data?.attributes?.interval?.value);

    // Check if the hero slider has a custom interval, is number and is bigger than zero
    if (
      convertedInterval &&
      !isNaN(convertedInterval) &&
      convertedInterval > 0
    ) {
      // convert to milliseconds
      interval = convertedInterval * 1000;
    }

    return heroSliderData?.map((slide, index) => {
      const isExpired = calculateIsExpired(slide?.value?.end_date);

      if (isExpired && !!slide?.value?.end_date) {
        return null;
      }

      const isVideo = slide?.kwargs?.value?.media_type?.value === 'video';
      const isFirstSlide = index === 0;

      return (
        <swiper-slide
          key={slide?.value?.alt}
          className={'swiper-slide-active group relative h-full w-full'}
          data-swiper-autoplay={`${interval}`}
        >
          {isVideo
            ? renderSlideVideo(slide)
            : renderSlideImage(slide, isFirstSlide)}
        </swiper-slide>
      );
    });
  };

  useEffect(() => {
    // Re-initialize insider_object
    if (typeof window !== 'undefined') {
      const insiderWindow = window as Window &
        typeof globalThis & {
          insider_object?: {
            page?: {
              type: string;
            };
          };
          Insider?: {
            eventManager?: {
              dispatch: (event: string) => void;
            };
          };
        };

      insiderWindow.insider_object = insiderWindow.insider_object || {};
      insiderWindow.insider_object.page = {
        type: 'Home'
      };
    }
  }, [data]);

  return (
    <div className="home-hero-slider mb-[.875rem] lg:mb-[2.1875rem]">
      <div className="relative">
        <swiper-container
          slides-per-view="auto"
          id={swiperId}
          class="h-auto"
          navigation="true"
          navigation-next-el={'#custom-nav-button--next-' + swiperId}
          navigation-prev-el={'#custom-nav-button--prev-' + swiperId}
          autoplay={autoPlay}
          loop="true"
        >
          {heroSliderData && renderSlides()}
        </swiper-container>

        {sliderEndDate?.length > 1 && (
          <>
            <button
              id={'custom-nav-button--prev-' + swiperId}
              aria-hidden="false"
              className="absolute z-[8] top-1/2 4xl:left-12 left-8 max-lg:hidden disabled:opacity-30 w-[3.25rem] h-[3.25rem]"
            >
              <ArrowLeftIcon
                className={'w-[1.25rem] h-[1.25rem] ml-[0.8rem]'}
              />
            </button>
            <button
              id={'custom-nav-button--next-' + swiperId}
              aria-hidden="false"
              className="absolute z-[8] top-1/2 4xl:right-12 lg:right-8 max-lg:hidden disabled:opacity-30 w-[3.25rem] h-[3.25rem]"
            >
              <ArrowRightIcon
                className={'w-[1.25rem] h-[1.25rem] ml-[0.8rem]'}
              />
            </button>
          </>
        )}
      </div>
      {/* Insider Script */}
      <Script id="insider-object-script-home" strategy="afterInteractive">
        {`
          window.insider_object = window.insider_object || {};
          window.insider_object.page = {
              "type": "Home"
          };
          if (typeof Insider !== 'undefined') {
            Insider?.eventManager?.dispatch('init-manager:re-initialize');
          }
        `}
      </Script>
      <Script
        async
        strategy="afterInteractive"
        src="//zsazsazsu.api.useinsider.com/ins.js?id=10010479"
      ></Script>

      {/* Insider Script */}
    </div>
  );
}
